import { useEffect } from 'react';
import {
  helperScrollUp,
  helperToggleFooterSticky,
  helperShowProgressSpinner,
  helperHideProgressSpinner,
} from '../helpers/index';

export default function SuspenseFallback(props) {
  useEffect(() => {
    helperShowProgressSpinner();
    return () => {
      helperScrollUp();
      helperToggleFooterSticky();
      helperHideProgressSpinner();
    }
  }, []);

  return (
    <span></span>
  );
}
