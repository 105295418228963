import locales from '../lang/locales';

let bookable = {
  id: '',
  thumbnail: '',
  thumbnail_data: {},
  branches: [],
  branch: '',
  subtype: '',
  room_type: '',
  title: {},
  description: {},
  display_on_booking_page: 1,
  hide_duration_on_booking_page: 0,
  service_category: 0,
  regular_price: 0,
  sale_percentage: 0,
  sale_price: 0,
  duration_hours: 0,
  duration_minutes: 0,
  buffer_hours_after: 0,
  buffer_minutes_after: 0,
  max_num_clients: 1,
  sku: '',
  type_of_tariffication: '',
  event_datetime_from: '',
  event_datetime_to: '',
};

Object.keys(locales).forEach((localeCode) => {
  bookable.title[localeCode] = '';
  bookable.description[localeCode] = '';
});

export default bookable;
