import en from './en/index';
import ru from './ru/index';
import uz from './uz/index';

const messages = {
  en: en,
  ru: ru,
  uz: uz,
};

export default messages;
