const moment = require('moment');
require('moment/locale/ru');
require('moment/locale/uz-latn');
require('moment-timezone');

let booking = {
  type: null,
  branch: null,
  branchServices: {},
  branchReservables: {},
  branchRentables: {},
  service: null,
  reservables: [],
  rentables: [],
  serviceProvider: null,
  stayDuration: 0,
  tariffication: null,
  rentingDuration: 1,
  datetime: {
    from: null,
    to: null,
  },
  client: {
    firstName: window.localStorage.getItem('client-firstName') || '',
    lastName: window.localStorage.getItem('client-lastName') || '',
    middleName: window.localStorage.getItem('client-middleName') || '',
    email: window.localStorage.getItem('client-email') || '',
    phone: window.localStorage.getItem('client-phone') || '',
    cityTown: window.localStorage.getItem('client-cityTown') || '',
    districtName: window.localStorage.getItem('client-districtName') || '',
    cityTownName: window.localStorage.getItem('client-cityTownName') || '',
    address: window.localStorage.getItem('client-address') || '',
    postcode: window.localStorage.getItem('client-postcode') || '',
    telegramUsername: window.localStorage.getItem('client-telegramUsername') || '',
    clientNotes: '',
    adminNotes: '',
    preferredLang: window.localStorage.getItem('user-lang') || 'uz',
    timezone: moment.tz.guess(true),
  },
  currentStep: 'branch',
  nextStepAvailable: false,
};

export default booking;
