import { default as bookableObject } from './bookable';
import { default as bookingObject } from './booking';
import { helperDefaultLang } from '../helpers/index';

let initialState = {
  hidePageTitle: false,
  pageTitle: null,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  },
  lang: window.localStorage.getItem('user-lang') || helperDefaultLang(),
  formFlashes: {},
  formErrors: {},
  loggedIn: window.localStorage.getItem('user-token') ? true : false,
  authUser: null,
  passwordResetLinkSentText: null,
  passwordResetText: null,
  emailJustVerified: false,
  businessEntities: {
    entities: [],
    entityToBeEdited: null,
    serviceCategories: null,
    serviceProviders: null,
    organizationTypes: {},
    organizationalAndLegalForms: {},
    bookingChannels: [],
    notificationChannels: [],
    min_time_till_event: [],
    max_time_till_event: [],
    minimum_allowed_time_duration: [],
  },
  locations: null,
  serviceCategoriesWithCount: [],
  organizations: {},
  administrators: [],
  chiefsOrganizations: [],
  administratorToBeEdited: null,
  serviceProviders: [],
  serviceProviderToBeEdited: null,
  bookable: JSON.parse(JSON.stringify(bookableObject)),
  organizationServiceRelatedData: null,
  bookables: {},
  shopfrontEntity: null,
  booking: bookingObject,
  branchesList: null,
  bookingsFilter: {
    branchId: '',
    createdAtFrom: '',
    createdAtTo: '',
    datetimeFrom: '',
    datetimeTo: '',
    client: '',
    status: '',
    code: '',
  },
  bookingToView: null,
  acceptedBookingInfo: null,
};

initialState.bookingSteps = {
  appointments: {
    branch: {
      passed: true,
      nextStep: 'service',
    },
    service: {
      passed: false,
      nextStep: 'provider',
      prevStep: 'branch',
    },
    provider: {
      passed: false,
      nextStep: 'date',
      prevStep: 'service',
    },
    date: {
      passed: false,
      nextStep: 'client',
      prevStep: 'provider',
    },
    client: {
      passed: false,
      nextStep: 'confirm',
      prevStep: 'date',
    },
    confirm: {
      passed: false,
      prevStep: 'client',
    },
  },
  rentals: {
    branch: {
      passed: true,
      nextStep: 'inventory',
    },
    inventory: {
      passed: false,
      nextStep: 'date',
      prevStep: 'branch',
    },
    date: {
      passed: false,
      nextStep: 'client',
      prevStep: 'inventory',
    },
    client: {
      passed: false,
      nextStep: 'confirm',
      prevStep: 'date',
    },
    confirm: {
      passed: false,
      prevStep: 'client',
    },
  },
  reservations: {
    branch: {
      passed: true,
      nextStep: 'place',
    },
    place: {
      passed: false,
      nextStep: 'date',
      prevStep: 'branch',
    },
    date: {
      passed: false,
      nextStep: 'client',
      prevStep: 'place',
    },
    client: {
      passed: false,
      nextStep: 'confirm',
      prevStep: 'date',
    },
    confirm: {
      passed: false,
      prevStep: 'client',
    },
  },
};

export default initialState;
