import { useEffect } from 'react';

function RedirectFromShortUrl(props) {
  useEffect(() => {
    return window.axios
      .get(`/b/${ props.match.params.bookingId }/${ props.match.params.bookingCode }`)
      .then((result) => {
        props.history.push(`/${ result.data.client.preferred_lang }/business-entity/${ result.data.organization_id }/${ result.data.organization.slug }/bookings/${ result.data.id }/${ result.data.code }`)
      });
    // eslint-disable-next-line
  }, []);

  return null;
}

export default RedirectFromShortUrl;
